<template>
  <v-app>
    <v-card>
      <v-card-title class="headline"><v-icon @click="back()" size="30" style="color: black">mdi-arrow-left</v-icon> &nbsp;{{ titleText }} Slider
      </v-card-title>
      <v-container>
        <v-card>
          <v-card-title class="headline">Slider Information</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                      outlined
                      dense
                      label="Slider Title*"
                      v-model="slider.title">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.slider.title.$error">This information is required</span>
                  <span class="text-danger" v-if="errors.title" >{{errors.title[0]}}</span>
                </v-col>
<!--                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                      outlined
                      dense
                      label="Heading text"
                      v-model="slider.heading_text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                      outlined
                      dense
                      label="Sub Heading Text*"
                      v-model="slider.subheading_text"
                  ></v-text-field>
                </v-col>-->
                <v-col md="12">
                  <v-text-field v-model="slider.position" label="Position" @keypress="onlyNumber"
                            dense outlined>
                  </v-text-field>
                  <span class="text-danger" v-if="errors.position" >{{errors.position[0]}}</span>
                  <span class="text-danger" v-if="$v.slider.position.$error">This information is required</span><br>
                  <span class="text-danger" v-if="!$v.slider.position.numeric">This Position Field should be numeric</span>
                </v-col>
                <v-col  v-bind:md="(slider.image != null) ? 8 : 12 ">
                  <v-file-input v-model="slider.image"
                                outlined
                                dense
                                @change="previewImage"
                                clearable
                                placeholder="Upload your documents"
                                label="File input" prepend-icon="mdi-paperclip">

                  </v-file-input>
                  <span class="text-danger" v-if="errors.image" >{{errors.image[0]}}</span>
                  <span class="text-danger" v-if="$v.slider.image.$error">This information is required</span>

                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="slider.image!=null">
                  <div class="text-center">
                    <v-img
                        :lazy-src="imgurl"
                        max-height="150"
                        max-width="250"
                        class="img-thumbnail"
                        :src="imgurl"></v-img>
                  </div>
                </v-col>
                <v-col md="12">
                  <v-card-title class="headline">Button</v-card-title>
                </v-col>
                <v-col md="6">
                  <v-switch
                      v-model="slider.button_status"
                      :label="`Button Status `+':'+ ' '+buttonStatusLabel "
                  ></v-switch>
                </v-col>
                <v-col md="6">
                  <v-switch
                      v-model="slider.button_target"
                      :label="`Button Target `+':'+ ' '+buttonTargetLabel "

                  ></v-switch>
                </v-col>
                <v-col cols="6" sm="6" md="12" v-if="slider.button_status">
                  <v-text-field
                      outlined
                      dense
                      label="Button Text"
                      v-model="slider.button_text"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="12" v-if="slider.button_status">
                  <v-text-field
                      outlined
                      dense
                      label="Button Link*"
                      v-model="slider.button_link"
                  ></v-text-field>
                </v-col>
                <v-col md="12">
                  <v-card-title class="headline">Secondary Button</v-card-title>
                </v-col>
                <v-col cols="3" md="6">
                  <v-switch
                      v-model="slider.second_button_status"
                      label="Button Status"
                      secondaryButtonStatusLabel
                      :label="`Secondary Button Status `+':'+ ' '+secondaryButtonStatusLabel "

                  ></v-switch>
                </v-col>
                <v-col cols="3" md="6">
                  <v-switch
                      v-model="slider.second_button_target"
                      label="Secondary Button Target"
                      :label="`Secondary Button Target `+':'+ ' '+secondaryButtonTargetLabel "

                  ></v-switch>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="slider.second_button_status">
                  <v-text-field
                      outlined
                      dense
                      label="Secondary Button Text"
                      v-model="slider.second_button_text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="slider.second_button_status">
                  <v-text-field
                      outlined
                      dense
                      label="Secondary Button Link*"
                      v-model="slider.second_button_link"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="12">
                  <v-textarea
                      dense
                      outlined
                      v-model="slider.description"
                      label="Outlined textarea">
                  </v-textarea>
                  <span class="text-danger" v-if="errors.description" >{{errors.description[0]}}</span>
                  <span class="text-danger" v-if="$v.slider.description.$error">This information is required</span>

                </v-col>

                <v-col cols="6">
                  <v-switch
                      v-model="slider.visibility"
                      :label="`Visibility` + ' : ' + visibilityLabel"
                  ></v-switch>
                  <span class="text-danger" v-if="errors.visibility" >{{errors.visibility[0]}}</span>
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="slider.status"
                      :label="`Status` + ' : ' + statusLabel"
                  ></v-switch>

                  <span class="text-danger" v-if="errors.status" >{{errors.status[0]}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-btn
                      class="ma-2"
                      color="btn btn-primary"
                      @click="createSlider">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>
  </v-app>
</template>
<script>
import BannerService from "@/services/cms/banner/BannerService";
import {required, numeric, requiredIf} from "vuelidate/lib/validators";

const banner=new BannerService();

export default {
  validations:{
    slider:{
      title:{required},
      description:{required},
      position:{required,numeric},
      image: {
        required: requiredIf(function (nestedModel) {
          return this.sliderId==null;
        })
      },
    }
  },
  data(){
    return{
      title:'',
      imgurl:null,
      slider:{
        title:'',
        type:'',
        heading_text:'',
        subheading_text:'',
        image:null,
        button_status:false,
        button_target:false,
        button_text:'',
        button_link:'',
        second_button_status:false,
        second_button_target:false,
        second_button_text:'',
        second_button_link:'',
        position:'',
        description:'',
        status:'',
        visibility:false,
      },
      label: {
        statusLabel: '',
        visibilityLabel: '',
        buttonTargetLabel:'',
        buttonStatusLabel:'',
        secondaryButtonStatusLabel:'',
        secondaryButtonTargetLabel:'',
      },
      errors:[],
    }
  },
  methods:{
    back(){
      this.$router.push({
        name: "slider",
      });
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.slider) {
        if (key === "image" && this.slider[key] != null && this.slider[key] != undefined) {
          formData.append('image', this.slider[key]);
        }else {
          if(this.slider[key]){
            formData.append(key,this.slider[key]);
          }
        }
      }
      return formData;
    },
    createSlider(){
      this.$v.$touch()
      if (this.$v.$error) {
        var element = this.$v;
        var top = element.offsetTop;
        window.scrollTo(0, top);
        setTimeout(() => {
          this.$v.$reset()
        }, 6000);
      } else {
        if(this.sliderId!=null || this.sliderId!=undefined){
          let formData = this.convertToFormData();
          banner
              .update(this.sliderId,formData)
              .then(response =>{
                this.$snotify.success("Slider updated");
                this.back();
              })
              .catch(error => {
                this.errors = error.response.data.errors;
              });
        }else{
          let formData = this.convertToFormData();
          banner
              .create(formData)
              .then(response =>{
                this.$snotify.success("Slider added");
                this.back();
              })
              .catch(error => {
                this.errors = error.response.data.errors;
              });
        }
      }
    },
    sliderDetail() {
      if (this.sliderId != null || this.sliderId != undefined) {
        banner
        .show(this.sliderId)
            .then(response => {
              this.slider=response.data.banner;
              this.imgurl=this.slider.image_path;
              this.slider.second_button_status=this.slider.second_button_status=="active" ? true :false;
              this.slider.button_status=this.slider.button_status=="active" ? true :false;
              this.slider.status=this.slider.status=="active" ? true :false;
              this.slider.visibility=this.slider.visibility=="visible" ? true : false;
              this.slider.second_button_target=this.slider.second_button_target=="parent" ? true : false;
              this.slider.button_target=this.slider.button_target=="parent" ? true : false;

            })
      }
    },
    previewImage(){
      if(this.slider.image != null){

        this.imgurl= URL.createObjectURL(this.slider.image)
      }else{
        this.imgurl = null;
      }
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
  },
  mounted() {
    this.sliderDetail();
  },
  computed:{
    titleText(){
      if(this.sliderId != null || this.sliderId != undefined){
        return this.title='Edit';
      }else{
        return this.title='Create New';
      }
    },
    sliderId () {
      return this.$route.params.sliderId;
    },
    statusLabel: function () {
      if(this.slider && this.slider.status==true){
        return this.label.statusLabel=`Active`
      }else{
        return this.label.statusLabel="Inactive"
      }
    },
    visibilityLabel: function () {
      if(this.slider && this.slider.visibility==true){
        return this.label.visibilityLabel="Visible"
      }else{
        return this.label.visibilityLabel="Not Visible"
      }
    },
    buttonStatusLabel:function (){
      if(this.slider && this.slider.button_status==true){
        return this.label.buttonStatusLabel="Available"
      }else{
        return this.label.buttonStatusLabel="Not Available"
      }
    },
    secondaryButtonStatusLabel:function (){
      if(this.slider && this.slider.second_button_status==true){
        return this.label.secondaryButtonStatusLabel="Available"
      }else{
        return this.label.secondaryButtonStatusLabel="Not Available"
      }
    },
    buttonTargetLabel:function (){
      if(this.slider && this.slider.button_target==true){
        return this.label.buttonTargetLabel="Available"
      }else{
        return this.label.buttonTargetLabel="Not Available"
      }
    },
    secondaryButtonTargetLabel:function (){
      if(this.slider && this.slider.second_button_target==true){
        return this.label.secondaryButtonTargetLabel="Available"
      }else{
        return this.label.secondaryButtonTargetLabel="Not Available"
      }
    }
  }
}
</script>